<template>
  <Services />
</template>

<script>
import Services from "@/components/Services.vue";
export default {
  name: "Store",
  components: {
    Services
  }
};
</script>
