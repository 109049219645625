<template>
  <div id="products">
    <b-container class="py-5">
      <h2 class="lead">
        Products page coming soon. Please contact me to discuss products. Thank you.
      </h2>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "Products"
};
</script>

<style>
#products {
  min-height: 25vh;
}
</style>
